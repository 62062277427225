import React from 'react'
import * as PropTypes from 'prop-types'
import { MDXProvider } from '@mdx-js/react'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import { Link, graphql } from 'gatsby'
import styled from '@emotion/styled'
import { Styled } from 'theme-ui'

import Layout from '../components/layout'
import Image from '../components/image'
import SEO from '../components/seo'
import { createPath } from '../helpers'

const Projects = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  > * {
    flex: 0 0 ${({ theme }) => 260}px;
  }

  display: grid;
  grid-template-columns: repeat(
    auto-fit,
    minmax(
      ${({ minWidth = '280px' }) => minWidth},
      ${({ maxWidth = '1fr' }) => maxWidth}
    )
  );
  grid-gap: ${({ theme }) => theme.spacing.s2}px;
`

const ProjectWrapper = styled.div``

const TeaserImageWrapper = styled.div`
  max-height: 30vh;
  overflow: hidden;

  & img,
  svg {
    max-height: 30vh;
    display: block;
  }
`

const ProjectTitle = styled(Styled.h1)`
  margin: ${({ theme }) => theme.spacing.s2}px 0 0;
`

const ProjectMeta = styled.div`
  margin: ${({ theme }) => theme.spacing.s1}px 0;
  opacity: 0.8;
`

const Project = function({ project }) {
  const {
    title,
    teaserImage,
    teaser: { childMdx: teaser },
    tags,
    dateStart,
    dateEnd,
    slug,
  } = project

  const path = createPath({ slug, prefix: 'project' })

  return (
    <ProjectWrapper>
      {teaserImage && (
        <Link to={path}>
          <TeaserImageWrapper>
            <Image
              {...teaserImage}
              alt={title}
              fluid={{
                ...teaserImage.fluid,
                base64: teaserImage.sqip
                  ? teaserImage.sqip.dataURI
                  : teaserImage.fluid.base64,
              }}
            />
          </TeaserImageWrapper>
        </Link>
      )}

      <ProjectTitle>
        <Link to={path}>{title}</Link>
      </ProjectTitle>
      <ProjectMeta>
        {[
          [`since ${dateStart}`, dateEnd].filter(Boolean).join(' till '),
          tags && tags.join(' / '),
        ]
          .filter(Boolean)
          .join(' • ')}
      </ProjectMeta>

      <MDXProvider>
        <MDXRenderer>{teaser.body}</MDXRenderer>
      </MDXProvider>

      <Link className="more" to={path} aria-label={`Learn more about ${title}`}>
        More
      </Link>
    </ProjectWrapper>
  )
}

Project.propTypes = {
  project: PropTypes.object.isRequired,
}

const ProjectPage = ({ data }) => {
  const projects = data.allContentfulProject.edges.map(({ node }) => (
    <Project key={node.id} project={node} />
  ))
  return (
    <Layout>
      <SEO title="Home" />
      <Projects>{projects}</Projects>
    </Layout>
  )
}

ProjectPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default ProjectPage

export const pageQuery = graphql`
  query projectsQuery {
    allContentfulProject {
      edges {
        node {
          id
          contentful_id
          slug
          title
          dateStart(formatString: "DD.MM.YY")
          # dateEnd(formatString: "DD.MM.YY")
          tags
          teaser {
            childMdx {
              body
            }
          }
          teaserImage {
            contentful_id
            file {
              contentType
              details {
                image {
                  height
                  width
                }
              }
            }
            svgContent
            sqip(mode: 4, numberOfPrimitives: 24, blur: 0) {
              dataURI
            }
            fluid(maxWidth: 300) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`
